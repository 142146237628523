import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="row">
          <div className="three columns">
            <ul>
              <li className="first last">
                <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
                <ul>
                  <li className="first last">
                    <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                      De werkzame stof: loperamide
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li className="first last">
                <a href="/imodium-behandelen-diarree/">
                  IMODIUM® Geneesmiddelen
                </a>
                <ul>
                  <li className="first">
                    <a href="/imodium-behandelen-diarree/imodium-instant/">
                      IMODIUM® Instant Smelttablet
                    </a>
                  </li>
                  <li>
                    <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                      IMODIUM® Capsules
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul>
              <li className="first last">
                <a href="/feiten-over-diarree/">Feiten over diarree</a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                      Tips voor behandeling
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                      Oorzaken van diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/stress-en-diarree/">
                      Stress en diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/buikgriep/">
                      Virale of bacteriële infectie
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                      Diarree tijdens de menstruatie
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/chronische-diarree/">
                      Chronische Diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/eenartsraadplegen/">
                      Een arts raadplegen?
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                      Gezonde eetgewoonten
                    </a>
                  </li>
                  
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul></ul>
            <ul>
              <li className="first last">
                <a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                  <li className="first">
                    <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                      Reizigersdiarree vermijden
                    </a>
                  </li>
                  <li>
                    <a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">
                      Reizigersdiarree behandelen
                    </a>
                  </li>
                  <li>
                    <a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul>
              <li className="first">
                <a href="/diarree-bij-kinderen/">Diarree bij kinderen</a>
              </li>
              <li className="here parent-here">
                <a href="/woordenlijst/">Woordenlijst diarree</a>
              </li>
              <li className="last">
                <a href="/veelgestelde-vragen-diarree/">FAQ</a>
              </li>
              <li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="twelve columns">
            <nav className="nav">
              <ol className="uilist-hor">
                <li className="first">
                  <a href="/sitemap/">Sitemap</a>
                </li>
                <li>
                  <a href="/juridische-kennisgeving/">
                    Juridische Kennisgeving
                  </a>
                </li>
                <li>
                  <a href="/privacybeleid/">Privacybeleid</a>
                </li>
                <li>
                  <a href="/cookiebeleid/">Cookiebeleid</a>
                </li>
                <li className="last">
                  <a href="/contact/">Contact</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="disclaimer">
          <div className="row">
            <div className="twelve columns">
              <p>
                © Johnson &amp; Johnson Consumer B.V. 2010-2017. KOAG nr
                33-1214-1700. Laatst bijgewerkt op: 18 december 2014.{" "}
              </p>
              <p>
                Deze site is gepubliceerd door Johnson &amp; Johnson Consumer
                B.V., die als enige verantwoordelijk is voor de inhoud van de
                site. De site is bedoeld voor een Nederlands publiek.
                <br />
                <br />
                IMODIUM® bevat loperamide voor het verlichten van diarree. Lees
                voor gebruik de bijsluiter. IMODIUM® mag niet gebruikt worden
                bij kinderen jonger dan 8 jaar. Tussen 2 en 8 jaar enkel Imodium
                orale drank gebruiken op medisch voorschrift. Niet gebruiken bij
                aanhoudende buikpijn, bij slijm of bloed in de ontlasting.
              </p>
            </div>
          </div>
        </div>
       
      </footer>
    );
  }
}

export default Footer;
