import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/privacybeleid/"> Privacybeleid</a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-39">
            <h1>Privacybeleid</h1>
            <p>
            Johnson &amp; Johnson Consumer BV is begaan met uw privacy en wil dat u vertrouwd bent met hoe wij informatie verzamelen, gebruiken en doorgeven. Dit Privacybeleid omschrijft onze praktijken in verband met de informatie die wij of onze dienstverleners verzamelen via de door ons beheerde website of applicatie (hierna de ‘<strong>Dienst</strong>’ genoemd) waar vandaan u dit Privacybeleid hebt bereikt. Door ons persoonsgegevens te verstrekken of door de Dienst te gebruiken, bevestigt u dat u dit Privacybeleid gelezen en begrepen heeft.
            </p>
            <h2>
              <br />
              GEBRUIK DOOR MINDERJARIGEN
            </h2>
            <p>
            De Dienst is niet gericht op personen jonger dan 16.&nbsp;Wij vragen van deze personen geen persoonsgegevens via de Dienst te verstrekken. Als uw kind persoonsgegevens heeft ingediend en u wilt verzoeken dat dergelijke persoonsgegevens worden verwijderd, neem dan contact met ons op zoals onder ‘Contact met ons opnemen’ wordt uitgelegd.
            </p>
            <h2>
              <br />
              VERZAMELING VAN INFORMATIE
            </h2>
            <p>We kunnen u vragen om persoonsgegevens te versturen zodat u kunt gebruikmaken van bepaalde functionaliteiten (zoals de nieuwsbrief, tips/suggesties, of de verwerking van uw bestelling) of om deel te nemen aan een bepaalde activiteit (zoals wedstrijden of andere promotionele acties). U zult worden geïnformeerd welke gegevens vereist zijn en welke optioneel zijn.</p>

<p>Wij kunnen de gegevens die u hebt verstrekt combineren met andere informatie die wij over u hebben verzameld, zowel on- als offline, inclusief bijvoorbeeld uw aankoopgeschiedenis. We kunnen ze ook combineren met informatie die wij over u krijgen van andere Kenvue filialen</p>

<p>Als u ons persoonsgegevens over een andere persoon verstrekt, verklaart u dat u daar de toelating voor heeft en dat u ons toelaat om die informatie te gebruiken in overeenstemming met dit Privacybeleid.</p>
            <h2>
              <br />
              GEVOELIGE INFORMATIE
            </h2>
            <p>
            Behalve als wij het specifiek vereisen of verzoeken, vragen wij u ons geen gevoelige persoonsgegevens te verstrekken of aan ons bekend te maken (bijv. nationale identificatienummers, informatie met betrekking tot ras of etnische afkomst, politieke mening, religie of filosofische overtuiging, gezondheid of medische aandoeningen, seksueel gedrag of seksuele geaardheid, criminele achtergrond of lidmaatschap van een vakbond, of biometrische of genetische gegevens) op of via de Dienst of op andere wijze. 
            </p>
            <h2>
              <br />
              AUTOMATISCHE VERZAMELING EN GEBRUIK VAN INFORMATIE
            </h2>
            <p>
              Wij en onze dienstverleners kunnen bepaalde gegevens automatisch
              verzamelen terwijl u&nbsp;de Dienst bezoekt. Lees het{" "}
              <a href="http://www.imodium.nl/cookiebeleid/">Cookiebeleid</a> voor gedetailleerde informatie over de cookies en andere traceertechnologieën die we op de Dienst&nbsp;gebruiken. Tenzij anders toegelaten door de toepasselijke wetgeving, plaatsen we cookies nadat we jouw toestemming gekregen hebben via de cookiebanner of het voorkeurenmenu. Het Cookiebeleid omvat informatie over hoe u deze technologieën kunt uitschakelen.
            </p>
            <p>
            Wij en onze dienstverleners kunnen ook automatisch informatie verzamelen en gebruiken, op de volgende manieren:
            </p>
            <p>
              <strong>Via uw browser:</strong> Bepaalde informatie wordt door de meeste browsers verzameld, zoals uw Media Access Control (MAC)-adres, computertype (Windows of Mac), uw schermresolutie, de naam en versie van uw besturingssysteem en het type en de versie van uw internetbrowser. We kunnen soortgelijke informatie verzamelen, zoals het type&nbsp;van uw toestel en de unieke toestelidentificatie, als u via een mobiel toestel&nbsp;de Dienst&nbsp;gebruikt. We gebruiken deze informatie om te verzekeren dat de Dienst correct functioneert.
            </p>
            <p>
              <strong>IP-adres:</strong> Uw IP-adres is een getal dat automatisch door uw internetprovider aan uw computer wordt toegekend. Een IP-adres wordt automatisch geïdentificeerd en vastgelegd in onze serverlogbestanden telkens als een gebruiker de Dienst bezoekt, samen met de duur van het bezoek en de pagina's die zijn bezocht. Het verzamelen van IP-adressen is een standaardgebruik en wordt door veel online diensten automatisch gedaan. We gebruiken IP-adressen om de gebruiksniveaus van de Dienst te berekenen, om serverproblemen te diagnosticeren en om de Dienst te beheren. We kunnen ook uw locatie bij benadering afleiden uit uw IP-adres.
              
              
            </p>
            <p>
              <strong>Apparaatinformatie:</strong> We kunnen informatie verzamelen over uw mobiele toestel, zoals een unieke toestelidentificatie, om te begrijpen hoe u gebruikt maakt van de Dienst.
            </p>
            <h2>
              <br />
              HOE WE INFORMATIE GEBRUIKEN EN DOORZENDEN
            </h2>
            <p>
            We gebruiken en geven&nbsp;de informatie die u aan ons hebt verstrekt door zoals het aan u wordt uitgelegd waar u de gegevens verstrekt. Zie het gedeelte ‘Keuzes en toegang’ hieronder om te weten te komen hoe u kunt kiezen om een bepaald gebruik of&nbsp;doorgifte te weigeren.
            </p>
            <p>
            Waar vereist door de toepasselijke wetgeving zullen wij op het moment van de gegevensverzameling uw toestemming vragen voor ons gebruik van uw persoonsgegevens.&nbsp; We kunnen, waar nodig, ook informatie van of over u gebruiken om een contract uit te voeren, om te voldoen aan een wettelijke verplichting (bijvoorbeeld voor onze verplichtingen inzake geneesmiddelenbewaking) of voor onze gerechtvaardigde zakelijke belangen.&nbsp; We kunnen ons ook baseren op andere rechtsgrondslagen, met name voor:
            </p>
            <p>
              <strong>
              Het aanbieden van de functionaliteiten van de Dienst en het voldoen aan uw verzoeken.
              </strong>
            </p>
            <ul>
              <li>
                om u de functionaliteit van de dienst aan te bieden en u de
                bijbehorende klantenservice te verlenen;
              </li>
              <li>
                om uw vragen te beantwoorden en aan uw verzoeken te voldoen,
                zoals het verzenden van documenten waarom u verzoekt of het
                sturen van e-mailherinneringen;
              </li>
              <li>
                om u belangrijke informatie toe te sturen met betrekking tot
                onze relatie met u of betreffende de Dienst, of over wijzigingen
                in onze algemene voorwaarden, beleidsregels en/of andere
                administratieve informatie.
              </li>
            </ul>
            <p>
              Wij zullen deze activiteiten uitvoeren om onze contractuele
              relatie met u te beheren en/of om een wettelijke verplichting na
              te komen.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Het behartigen van onze zakelijke doeleinden.</strong>
            </p>
            <ul>
              <li>
                voor gegevensanalyse, bijvoorbeeld om de efficiëntie van de
                Dienst te verbeteren;
              </li>
              <li>
                voor audits, om te controleren of onze interne processen
                functioneren zoals bedoeld en te voldoen aan juridische,
                wettelijke of contractuele vereisten;
              </li>
              <li>
                voor fraude- en beveiligingscontrole, bijvoorbeeld om
                cyberaanvallen of pogingen tot het plegen van
                identiteitsdiefstal te detecteren en te voorkomen;
              </li>
              <li>voor het ontwikkelen van nieuwe producten en diensten;</li>
              <li>
                voor het aanvullen, verbeteren of wijzigen van onze website of
                onze producten en diensten;
              </li>
              <li>
                voor het identificeren van trends in het gebruik van de Dienst,
                bijvoorbeeld om inzicht te krijgen in welke onderdelen van onze
                Dienst het meest interessant zijn voor gebruikers; en
              </li>
              <li>
                voor het bepalen van de doeltreffendheid van onze
                promotiecampagnes, zodat we onze campagnes kunnen aanpassen aan
                de behoeften en interesses van onze gebruikers.
              </li>
            </ul>
            <p>
              Wij zullen deze activiteiten uitvoeren om onze contractuele
              relatie met u te beheren, om een wettelijke verplichting na te
              komen, en/of omdat om onze gerechtvaardigd belangen te behartigen.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                Analyse van persoonlijke gegevens om gepersonaliseerde diensten
                aan te bieden.
              </strong>
            </p>
            <ul>
              <li>
                om u beter te begrijpen, zodat we onze interacties met u kunnen
                personaliseren en u informatie en/of aanbiedingen kunnen
                verstrekken die zijn afgestemd op uw interesses;
              </li>
              <li>
                om uw voorkeuren beter te begrijpen, zodat we via de Dienst
                content kunnen leveren die volgens ons relevant en interessant
                voor u zal zijn.
              </li>
            </ul>
            <p>
              Wij zullen gepersonaliseerde diensten verlenen met uw toestemming
              of omdat om onze gerechtvaardige belangen te behartigen.
            </p>
            <p>&nbsp;</p>
            <p>Wij geven ook informatie die via de Dienst is verzameld door:</p>
            <ul>
              <li>
                aan onze filialen voor doeleinden beschreven in dit
                Privacybeleid. Een lijst van onze filialen is beschikbaar op{" "}
                <a target="_blank" href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx">
                https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                </a>{" "}
                - toegang tot de toekomstige deponeringen van Kenvue bij SEC (Securities and Exchange Commission). Johnson &amp; Johnson Consumer B.V. is de partij die
                verantwoordelijk is voor het beheer van de gezamenlijk gebruikte
                Persoonsgegevens;
              </li>
              <li>
                aan onze externe partners met wie wij gezamenlijk een promotie
                aanbieden;
              </li>
              <li>
               aan onze externe dienstverleners die diensten leveren zoals websitehosting en -beheer, hosting van mobiele toepassingen, gegevensanalyse, betalingsverwerking, orderverwerking, aanbieden van infrastructuur, IT-diensten, klantenservices, e-mail en directe maildiensten, auditing en andere diensten om hen in staat te stellen diensten te leveren; en
              </li>
              <li>
              zoals toegelaten door de toepasselijke wetgeving, aan een externe partij in het geval van een reorganisatie, fusie, verkoop, joint venture, toewijzing, overdracht of andere afstoting van alle of een deel van ons bedrijf, onze activa of onze aandelen (ook in verband met een faillissement of vergelijkbare procedures).
              </li>
            </ul>
            <p>
              <br />
              Daarnaast gebruiken en geven we mogelijks uw informatie door voor zover wij dat noodzakelijk of geschikt achten: (a) om te voldoen aan een juridisch proces of de geldende wetgeving, waaronder mogelijk&nbsp;wetten buiten het land waar u woont; (b) om zoals toegelaten door de toepasselijke wetgeving aan verzoeken te voldoen van openbare en overheidsinstanties, waaronder mogelijk&nbsp;instanties buiten het land waar u woont; (c) om onze algemene voorwaarden te bekrachtigen; en (d) om de rechten, privacy, veiligheid of eigendom van ons, onze filialen, u of anderen te beschermen. We kunnen uw informatie ook gebruiken en doorgeven op andere manieren, nadat wij uw toestemming daartoe gekregen hebben.
              <br />
              We kunnen informatie die we automatisch verzamelen gebruiken en doorgeven zoals eerder beschreven in het gedeelte ‘Automatische&nbsp;verzameling en gebruik van informatie’.
            </p>
            <p>
            Waar toegelaten door de toepasselijke wetgeving kunnen we daarnaast informatie die personen niet kan identificeren, gebruiken en doorgeven voor welk doeleinde dan ook. Als we informatie die niet persoonlijk identificeerbaar is combineren met informatie die wel identificeerbaar is (zoals het combineren van uw naam met uw geografische locatie) zullen wij de gecombineerde informatie behandelen als persoonsgegevens zolang als deze gecombineerd is.
            </p>
            <h2>
              <br />
              KEUZES EN TOEGANG
            </h2>
            <p>
              <strong>
                Uw keuzes met betrekking tot ons gebruik en doorgifte van uw
                persoonsgegevens
              </strong>
            </p>
            <p>
              Wij bieden u keuzes met betrekking tot ons gebruik en doorgifte
              van uw persoonsgegevens voor marketingdoeleinden. U kunt bezwaar
              maken tegen:
            </p>
            <ul>
              <li>
                <strong>Marketingcommunicatie ontvangen van ons:</strong> Als u
                in de toekomst niet langer marketingcommunicatie van ons wilt
                ontvangen, kunt u bezwaar maken tegen de&nbsp;ontvangst
                ervan&nbsp;door contact met ons op te nemen via{" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG"
                  target="_blank"
                >
                  contactformulier
                </a>
                . Vermeld in uw verzoek aan ons uw naam, geef de vorm(en) van marketingcommunicatie aan die u niet langer wenst te ontvangen en vermeld de adres(sen) waar deze naartoe wordt/worden gestuurd. Als u bijvoorbeeld niet langer marketing-e-mails of directe mail van ons wenst te ontvangen, kunt u dat aangeven. Vermeld hierbij uw naam en e-mail of postadres. U kunt ook kiezen geen marketingmededelingen meer van ons te ontvangen door naar de Dienst te gaan en uw onlineprofiel bij te werken. Daarnaast kunt u bezwaar maken tegen de ontvangst van marketing-e-mails door de instructies te volgen die in een dergelijk bericht gegeven worden.
              </li>
              <li>
                <strong>Herinneringen ontvangen van ons:</strong> Als u niet
                langer medische herinneringen van ons wilt ontvangen, kunt u
                bezwaar maken tegen de ontvangst ervan door contact met ons op
                te nemen via{" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG"
                  target="_blank"
                >
                  contactformulier
                </a>
                . Vermeld in uw antwoord aan ons uw naam en e-mailadres of
                telefoonnummer waarop u de herinneringen van ons ontvangt.
              </li>
              <li>
                <strong>
                  Het delen van uw persoonsgegevens met filialen en externe
                  partners:
                </strong>{" "}
                Als u er eerder voor hebt gekozen om marketingcommunicatie te
                ontvangen van onze filialen of externe partners, kan u bezwaar
                maken tegen het delen van uw persoonsgegevens met die partijen
                voor hun direct-marketingdoelen door contact met ons op te nemen
                via{" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG"
                  target="_blank"
                >
                  contactformulier
                </a>
                . Vermeld in uw antwoord aan ons dat wij niet langer uw
                persoonsgegevens mogen delen met onze filialen en/of externe
                partners voor hun marketingdoeleinden, en vermeld uw naam en
                e-mailadres.
              </li>
            </ul>
            <p>
              <br />
              Wij proberen zo snel als redelijk mogelijk aan uw verzoek(en) te voldoen. Wij wijzen u erop dat als u bezwaar maakt zoals hierboven beschreven, wij niet in staat zullen zijn om uw persoonsgegevens te verwijderen uit de databases van onze filialen waarmee wij reeds uw persoonsgegevens hebben gedeeld (d.w.z. vanaf de datum dat we uw verzoek tot bezwaar hebben verwerkt). We zullen echten redelijke inspanningen leveren om onze filialen op de hoogte te stellen van uw verzoek. Als u ervoor kiest geen marketinggerelateerde berichten meer van ons te ontvangen, kunnen wij u nog wel belangrijke transactionele en administratieve berichten sturen waartegen u geen bezwaar kan maken.
            </p>
            <p>
              <br />
              <strong>
                Hoe u inzage&nbsp;kunt krijgen van uw persoonsgegevens, ze kan
                verbeteren of wissen
              </strong>
            </p>
            <p>
            Als u uw persoonsgegevens wilt nakijken, verbeteren, bijwerken, wissen of hun verwerking beperken, of als u een elektronische kopie van uw persoonsgegevens wil aanvragen om ze te kunnen doorsturen naar een ander bedrijf (in de mate dat de toepasselijke wetgeving u deze rechten toekent), neemt u contact met ons op via{" "}
              <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG">
                contactformulier
              </a>
              . We zullen uw verzoek zo snel als redelijk mogelijk beantwoorden, en niet later dan een maand na ontvangst. Als omstandigheden een vertraging van ons antwoord veroorzaken, zal u onmiddellijk op de hoogte gebracht worden en een datum ontvangen waarop wij u zullen antwoorden. U kan de Dienst ook bezoeken om uw online profiel bij te werken.
            </p>
            <h2>
              <br />
              GRENSOVERSCHRIJDENDE DOORGIFTE
            </h2>
            <p>
            Uw persoonsgegevens kunnen in elk land waar we faciliteiten of dienstverleners hebben worden opgeslagen en verwerkt. Door onze Dienst te gebruiken of door ons toestemming te geven (waar wettelijk verplicht), kan uw informatie doorgegeven worden naar andere landen dan het land waar u woont, inclusief de Verenigde Staten, waar andere gegevensbeschermingwetten kunnen gelden dan in uw eigen land. Niettemin, gepaste contractuele en andere maatregelen zijn getroffen om persoonsgegevens te beschermen wanneer ze verzonden&nbsp;worden naar onze filialen of derden in andere landen.
            </p>
            <p>
            Sommige landen buiten de Europese Economische Ruimte (EER) worden erkend door de Europese Commissie als landen waar&nbsp;een gepast niveau van gegevensbescherming geldt volgens de EER normen (de volledige lijst van deze landen is{" "}
              <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_nl">
                hier
              </a>{" "}
              beschikbaar. Voor doorgiften vanuit de EER naar landen die naar oordeel van de Europese Commissie niet voldoende bescherming bieden, hebben we verzekerd dat afdoende maatregelen getroffen werden, onder andere door te verzekeren dat de ontvanger gebonden is aan <strong>EU&nbsp;standaardbepalingen inzake gegevensbescherming</strong>, om uw persoonsgegevens te beschermen. U kan een kopie van deze maatregelen ontvangen door contact op te nemen met onze functionaris voor gegevensbescherming zoals uitgelegd in het onderstaande gedeelte&nbsp;‘Contact met ons opnemen’.
            </p>
            <h2>
              <br />
              BEVEILIGING
            </h2>
            <p>
            Wij streven ernaar gebruik te maken van redelijke organisatorische, technische en administratieve maatregelen die ontworpen zijn om de persoonsgegevens in ons bezit te beschermen. Helaas kan voor geen enkele gegevensoverdracht over het internet noch voor enig gegevensopslagsysteem een 100% veiligheidsgarantie worden geboden. Als u redenen hebt om te geloven dat uw interactie met ons niet langer veilig is (bijvoorbeeld als u meent dat de beveiliging van een account die u bij ons heeft, is aangetast), breng ons daar dan onmiddellijk van op de hoogte, in overeenstemming met de onderstaande gedeelte ‘Contact met ons opnemen’.
            </p>
            <h2>
              <br />
              BEWAARPERIODE
            </h2>
            <p>
            We zullen uw persoonsgegevens bewaren voor zo lang als nodig of toegelaten is voor het doel/de doelen waarvoor ze verkregen werden. De criteria die gebruikt worden om onze bewaarperiodes te bepalen, omvatten: (i) de duur van onze lopende relatie met u en van de Dienst die we&nbsp;u aanbieden; (ii) of er al dan niet een wettelijke verplichting is waaraan wij onderworpen zijn; en (iii) of bewaring al dan niet raadzaam is wegens onze juridische positie (zoals aangaande toepasselijke verjaringstermijn(en), een rechtszaak of onderzoeken van een toezichthouder).
            </p>
            <p>
            Als wij bijvoorbeeld uw persoonsgegevens verzamelen voor het afsluiten van een overeenkomst, zullen we deze gegevens voor een periode van 15 jaar vanaf afloop van het contract bewaren.
            </p>
            <p>
            In gevallen dat u ons toestemming gegeven heeft u marketing-informatie toe te doen laten komen slaan wij uw gegevens op tot u deze toestemming intrekt resp. voor 5 jaar.
            </p>
            <p>
            Houd er echter rekening mee dat dit geen alomvattende lijst van bewaarperiodes is. Uw persoonsgegevens kunnen voor een langere periode bewaard worden aan de hand van de criteria uit de eerste paragraaf van dit gedeelte, in het bijzonder punten (ii) en (iii).
            </p>
            <h2>
              <br />
              WEBSITES EN DIENSTEN VAN DERDEN
            </h2>
            <p>
            Deze Dienst kan links bevatten naar sites van derden. Dit Privacybeleid is niet van toepassing op en wij zijn niet verantwoordelijk voor de privacy, informatie en praktijken van derden, inclusief derden die een site of online dienst exploiteren (inclusief, zonder beperking, alle toepassingen) die beschikbaar is via deze Dienst of waarnaar deze Dienst een link bevat. De beschikbaarheid van of het plaatsen van een link naar een dergelijke site of dienst op de Dienst houdt geen aanbeveling in van ons of onze filialen.
            </p>
            <h2>
              <br />
              CONTACT MET ONS OPNEMEN
            </h2>
            <p>
            Johnson &amp; Johnson Consumer BV, gevestigd te AZ Utrecht, is het bedrijf verantwoordelijk voor het verzamelen, het gebruik en de doorgifte van persoonsgegevens volgens dit Privacybeleid.
              <br />
              Neem als u vragen hebt over dit Privacybeleid contact op met ons
              via{" "}
              <a
                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pG"
                target="_blank"
              >
                contactformulier
              </a>{" "}
              of schrijf naar het volgende adres:
            </p>
            <p>
              Johnson &amp; Johnson Consumer BV
              <br />
              Stadsplateau 27-29
              <br />
              3521 AZ Utrecht
              <br />
              Nederland
            </p>
            <p>
              U kan ook contact opnemen met onze functionaris voor
              gegevensbescherming die verantwoordelijk is voor uw land of regio,
              indien van toepassing, via{" "}
              <a href="mailto:emeaprivacy@kenvue.com">
              emeaprivacy@kenvue.com
              </a>
              .
            </p>
            <h2>
              <br />
              EEN KLACHT INDIENEN BIJ EEN TOEZICHTHOUDER
            </h2>
            <p>
              U kan een klacht indienen bij een toezichthoudende autoriteit die
              bevoegd is voor uw land of regio. Klik{" "}
              <a href="https://edpb.europa.eu/about-edpb/board/members_nl">
                hier
              </a>{" "}
              voor de contactinformatie van dergelijke autoriteiten.
            </p>
            <h2>
              <br />
              UPDATES VAN DIT PRIVACYBELEID
            </h2>
            <p>
            Wij kunnen dit Privacybeleid wijzigen. Veranderingen in dit Privacybeleid treden in werking als wij het herziene Privacybeleid op de Dienst plaatsen. Uw gebruik van de Dienst na deze wijzigingen betekent dat u akkoord gaat met het herziene Privacybeleid. We bevelen aan dat u het Privacybeleid regelmatig naleest wanneer u de Dienst bezoekt. Dit beleid werd het laatst bijgewerkt op <strong>02.06.2023</strong>.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
